import './OrderPage.scss';

import React, { Component, Fragment } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';

import reasonCodes, { reasonCodeDescription, reasonCodeLookup } from '../../../data/reasonCodes';
import StandardLoader from '../../../layouts/admin/StandardLoader';
import { layoutActions } from '../../../redux/actions/layouts';
import { orderActions } from '../../../redux/actions/order';
import microservices from '../../../services/microservices';
import { getUserPermissions, handleUnauthorizedError } from '../../../utils';
import { getOrderPaymentStatus } from '../../../utils/orders';
import { getDeliveryRefund } from '../../../utils/prices';
import { formatDateDefault } from '../../../utils/time';
import IssueFullRefund from '../amend-item/IssueFullRefund';
import OrderPageCollapse from './OrderPageCollapse';
import OrderPageItemTable from './OrderPageItemTable';

const defaults = {
    newDeliveryRefund: '0.00',
    deliveryRefundReason: '',
};
class OrderPage extends Component {
    state = {
        availableFacia: [],
        selectedFacia: '',
        currentNotesPage: 1,
        notesCollapseOpen: false,
        refundModalOpen: false,
        refundReason: '',
        changeMade: false,
        productSizes: {},
        noteText: '',
        noteData: [],
        notesRowsPerPage: 2,
        refundModalType: 'refund',
        adminDeliveryOptions: [],
        deliveryOption: {},
        orderNotes: {},
        orderStatus: '',
        issueFullRefundSubmitted: false,
        fullActionPerformed: false,
        productSearchModalOpen: false,
        chosenProductSearchItem: null,
        submitting: false,
        chosenRefundDeliveryID: false,
        disableAllAction: false,
        newDeliveryRefund: defaults.newDeliveryRefund,
        deliveryRefundReason: defaults.deliveryRefundReason,
        canRefundOrders: false,
        canReturnOrders: false,
        canModifyOrders: false,
    };

    // Class properties
    faciaDisplayText = {
        jdsports: 'JD',
        jdsportsuk: 'JD',
        footpatrol: 'FOOTPATROL',
        scotts: 'SCOTTS',
        size: 'SIZE?',
        tessuti: 'TESSUTI',
        blacks: 'BLACKS',
        millets: 'MILLETS',
        ultimateoutdoors: 'ULTIMATE-OUTDOORS',
    };

    refundModalTypes = Object.freeze({
        REFUND: 'refund',
        REFUNDRETURN: 'refundreturn',
    });

    async componentDidMount() {
        const { currentUser, order } = this.props;

        const { canRefundOrders, canReturnOrders } = getUserPermissions(currentUser);
        this.setState({
            canRefundOrders,
            canReturnOrders,
            canModifyOrders: canRefundOrders || canReturnOrders,
        });

        this.updateDeliveryRefundDefaultValue();

        const adminDeliveryOptions = await this.getAdminDeliveryOptions();

        if (typeof adminDeliveryOptions.error === 'undefined') {
            this.setAdminDeliveryOptionsState(adminDeliveryOptions);
        } else {
            console.log('Error getting admin delivery options', adminDeliveryOptions.error);
        }

        if (typeof order.notes !== 'undefined') {
            this.setNoteDataState(order.notes.reverse());
        }

        // get facia list available from order
        const orderFacias = this.getFaciaList(order.items);

        this.setAvailableFaciaState(orderFacias);

        await this.setProductOptions(order.items);

        // old dev data doesn't have delivery option set
        if (typeof order.delivery.carrierCode !== 'undefined') {
            const orderDeliveryOption = await this.getAdminDeliveryOption(
                order.delivery.carrierCode,
            );

            if (orderDeliveryOption) {
                this.setDeliveryOptionState(order.delivery);
            }
        } else {
            this.setDeliveryOptionState(order.delivery);
        }

        const orderNotes = this.getNotes();
        this.setOrderNotes(orderNotes);

        const orderStatus = getOrderPaymentStatus(order.items, order.payment);

        if (orderStatus.text === 'pending' || this.isFullRefundInProgress(order)) {
            this.setDisableAllActionState(true);
        }

        this.setOrderStatus(orderStatus);

        this.setChosenRefundDeliveryIDState(order.delivery.ID);

        this.checkFullActionPerformed();
    }

    isFullRefundInProgress(order) {
        for (let fascia in order.items) {
            if (order.items[fascia].status.toLowerCase() === 'refundinprogress') {
                return true;
            }
        }
        return false;
    }

    componentDidUpdate() {
        this.checkFullActionPerformed();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    asyncSetState = state => {
        return new Promise(resolve => {
            this.setState(state, () => {
                resolve();
            });
        });
    };

    setNewDeliveryRefundState = (newDeliveryRefund = false) => {
        if (newDeliveryRefund === false) {
            newDeliveryRefund = defaults.newDeliveryRefund;
        }

        this.setState({ newDeliveryRefund }, () => this.checkChangeMade());
    };

    setDeliveryRefundReasonState = (deliveryRefundReason = false) => {
        if (deliveryRefundReason === false) {
            deliveryRefundReason = defaults.deliveryRefundReason;
        }
        this.setState({ deliveryRefundReason }, () => this.checkChangeMade());
    };

    setFullActionPerformedState = fullActionPerformed => this.setState({ fullActionPerformed });

    setProductOptions = async orderItems => {
        for (let itemBrand in orderItems) {
            for (let itemKey in orderItems[itemBrand].items) {
                const { productID, facia } = orderItems[itemBrand].items[itemKey];
                let plu = productID.slice(productID.indexOf('_') + 1);

                let sizesAvailable = await this.getProductSizes(facia, plu);

                if (typeof sizesAvailable.error === 'undefined') {
                    const { productSizes } = this.state;
                    let newProductSizes = Object.assign({}, productSizes);

                    newProductSizes[productID] = sizesAvailable.productOptions.filter(
                        ({ inStock, quantity }) => inStock === true && quantity > 0,
                    );

                    this.setState({ productSizes: newProductSizes });
                }
            }
        }
    };

    // Set state methods
    toggleNotesCollapseOpen = () => {
        const { notesCollapseOpen } = this.state;

        this.setState({ notesCollapseOpen: !notesCollapseOpen });
    };

    onSelectedFaciaChange = e => {
        const { value } = e.target;
        this.setSelectedFaciaState(value);
    };

    setAdminDeliveryOptionsState = subject => {
        const adminDeliveryOptions = [...subject];
        this.setState({ adminDeliveryOptions });
    };

    setSelectedFaciaState = selectedFacia => this.setState({ selectedFacia });

    setAvailableFaciaState = availableFacia => this.setState({ availableFacia });

    setRefundModalState = refundModalOpen => this.setState({ refundModalOpen });

    setNoteTextState = (noteText, callback) => {
        if (typeof callback === 'function') {
            this.setState({ noteText }, () => callback());
        } else {
            this.setState({ noteText });
        }
    };

    setNoteDataState = noteData => this.setState({ noteData });

    setRefundReasonState = e => {
        const { value: refundReason } = e.target;
        this.setState({ refundReason });

        if (refundReason.length) {
            this.setIssueFullRefundSubmitted(false);
        } else {
            this.setIssueFullRefundSubmitted(true);
        }
    };

    toggleChangeMadeState = () => {
        const { changeMade: oldChangeMade } = this.state;

        this.setState({ changeMade: !oldChangeMade });
    };

    setRefundModalTypeState = refundModalType => {
        switch (refundModalType) {
            case this.refundModalTypes.REFUND:
            case this.refundModalTypes.REFUNDRETURN:
                break;
            default:
                return;
        }

        this.setState({ refundModalType });
    };

    setNotesRowsPerPageState = (notesRowsPerPage, callback = null) => {
        if (typeof callback === 'function') {
            this.setState({ notesRowsPerPage }, () => callback());
        } else {
            this.setState({ notesRowsPerPage });
        }
    };

    setCurrentNotesPageState = (currentNotesPage, callback = null) => {
        if (typeof callback === 'function') {
            this.setState({ currentNotesPage }, () => callback());
        } else {
            this.setState({ currentNotesPage });
        }
    };

    setDeliveryOptionState = deliveryOption => this.setState({ deliveryOption });

    setOrderNotes = orderNotes => this.setState({ orderNotes });

    setOrderStatus = orderStatus => this.setState({ orderStatus });

    setIssueFullRefundSubmitted = issueFullRefundSubmitted =>
        this.setState({ issueFullRefundSubmitted });

    setSubmittingState = submitting => this.setState({ submitting });

    setChosenRefundDeliveryIDState = chosenRefundDeliveryID =>
        this.setState({ chosenRefundDeliveryID });

    setDisableAllActionState = disableAllAction => this.setState({ disableAllAction });

    // Methods
    // check if all items have been refunded
    checkAllItemsRefunded = () => {
        const { order } = this.props;

        let breakOut = false;
        for (let itemBrand in order.items) {
            for (let itemKey in order.items[itemBrand].items) {
                const { lines, quantity: quantityRemaining } =
                    order.items[itemBrand].items[itemKey];

                if (lines === undefined) {
                    breakOut = true;
                    break;
                }

                let refundCount = 0;

                for (let lineNumber in lines) {
                    const amends = lines[lineNumber].amends;

                    if (amends.length < 2) {
                        if (amends[0].type !== 'refund') {
                            breakOut = true;
                            break;
                        }
                        refundCount += amends[0].quantity;
                    } else {
                        if (amends[0].type !== 'refund' || amends[1].type !== 'refund') {
                            breakOut = true;
                            break;
                        }
                        if (amends[0].type === 'refund') {
                            refundCount += amends[0].quantity;
                        }
                        if (amends[1].type === 'refund') {
                            refundCount += amends[1].quantity;
                        }
                    }
                }

                if (refundCount !== quantityRemaining) {
                    breakOut = true;
                    break;
                }
            }
            if (breakOut) {
                break;
            }
        }

        // if never broke out of loop all items have refunds
        return !breakOut;
    };

    getProductActionsCount = ({ lines }) => {
        return Object.values(lines).filter(({ amends }) => amends && amends.length > 0).length;
    };

    getFaciaList = items => {
        const faciaDisplayText = this.faciaDisplayText;
        let newAvailableFacia = [];

        for (let itemKey in items) {
            if (typeof faciaDisplayText[itemKey] !== 'undefined') {
                newAvailableFacia.push(itemKey);
            }
        }

        return newAvailableFacia;
    };

    getItemBySku = sku => {
        const { order } = this.props;
        let returnItem = null;

        for (let itemBrand in order.items) {
            for (let itemKey in order.items[itemBrand].items) {
                let item = order.items[itemBrand].items[itemKey];

                if (item.ID === sku) {
                    returnItem = item;
                    break;
                }
            }
        }

        return returnItem;
    };

    getDeliveryRefundReasonEl() {
        return document.getElementsByClassName('delivery-refund-reason-input');
    }

    getNewDeliveryRefundEl() {
        return document.getElementsByClassName('new-delivery-refund-input');
    }

    updateDeliveryRefundDefaultValue() {
        const { newDeliveryRefund } = this.state;
        const { order } = this.props;
        const { delivery } = order;
        const { price = 0 } = delivery;

        const result = price - getDeliveryRefund(order);

        if (newDeliveryRefund === defaults.newDeliveryRefund) {
            defaults.newDeliveryRefund = `${(result / 100).toFixed(2)}`;
            this.setNewDeliveryRefundState(defaults.newDeliveryRefund);
        }
    }

    newDeliveryRefundHasNewValue() {
        const { newDeliveryRefund } = this.state;

        return newDeliveryRefund !== '0.00';
    }

    deliveryRefundReasonHasNewValue() {
        const { deliveryRefundReason } = this.state;

        return deliveryRefundReason !== defaults.deliveryRefundReason;
    }

    getNotes = () => {
        const { notesRowsPerPage: perPage } = this.state;
        let { currentNotesPage: page, noteData } = this.state;

        const pageCount = Math.ceil(noteData.length / perPage);
        const total = noteData.length;
        let offset = 0;

        if (page > pageCount) {
            page = 1;
            this.setCurrentNotesPageState(page);
        }

        if (page > 1) {
            offset = (page - 1) * perPage;
        }

        const notes = [];

        if (noteData.length) {
            if (noteData.length >= perPage) {
                for (let i = offset; i < offset + perPage; i++) {
                    if (typeof noteData[i] !== 'undefined') {
                        notes.push(noteData[i]);
                    }
                }
            } else {
                for (let i = 0; i < noteData.length; i++) {
                    if (typeof noteData[i] !== 'undefined') {
                        notes.push(noteData[i]);
                    }
                }
            }
        }

        const returnObj = {
            pageCount,
            perPage,
            notes,
            total,
            page,
        };

        return returnObj;
    };

    getProductSizes = async (facia, plu) => {
        try {
            const response = await microservices.product.get(`/admin?facia=${facia}&plu=${plu}`);

            return response.data;
        } catch (error) {
            handleUnauthorizedError(error);
            return { error };
        }
    };

    getAdminDeliveryOption = async carrierCode => {
        const { adminDeliveryOptions } = this.state;

        const r = adminDeliveryOptions.filter(({ carrierCode: cc }) => cc === carrierCode);
        if (!r.length) {
            return false;
        }

        return r.pop();
    };

    getAdminDeliveryOptions = async () => {
        return await microservices.delivery.get(`/admin`).then(
            result => result.data.data,
            error => {
                handleUnauthorizedError(error);
                return { error };
            },
        );
    };

    getProductSizeOptions = productID => {
        const { productSizes } = this.state;

        let optionValues = [];

        for (let i = 0; i < productSizes[productID].length; i++) {
            let option = productSizes[productID][i];

            optionValues.push(option.optionValue);
        }

        return optionValues
            .map(size => parseInt(size))
            .sort((a, b) => a - b)
            .filter((size, index, self) => {
                return self.indexOf(size) === index;
            });
    };

    getFaciaItemByID = (ID, facia) => {
        const { order } = this.props;

        let result = null;

        for (let itemKey in order.items[facia].items) {
            const productItem = order.items[facia].items[itemKey];

            if (productItem.ID !== ID) {
                continue;
            }

            result = productItem;
            break;
        }

        return result;
    };

    getRefundEdit = (ID, quantity) => {
        let { disableAllAction } = this.state;
        let quantityOptions = [];

        for (let i = 1; i <= quantity; i++) {
            quantityOptions.push(i);
        }

        const { canModifyOrders, canRefundOrders, canReturnOrders } = this.state;
        const orderActionsAvailable = [];
        if (canReturnOrders) {
            orderActionsAvailable.push('Return');
        }
        if (canRefundOrders) {
            orderActionsAvailable.push('Refund');
        }
        if (canReturnOrders && canRefundOrders) {
            orderActionsAvailable.push('Return & Refund');
        }

        return (
            <Fragment>
                <td className="action-1">
                    <select
                        name={`action-${ID}`}
                        className="form-control action-select"
                        onChange={e => {
                            this.onActionCreateRowChange(e);
                            this.checkChangeMade();
                        }}
                        disabled={
                            !canModifyOrders || disableAllAction || quantity === 0 ? true : false
                        }
                    >
                        <option value="">Action:</option>
                        {orderActionsAvailable.map((action, index) => (
                            <option key={index}>{action}</option>
                        ))}
                    </select>
                    <select
                        name={`reason-${ID}`}
                        className="form-control action-select"
                        onChange={e => {
                            this.onActionCreateRowChange(e);
                            this.checkChangeMade();
                        }}
                        disabled="disabled"
                    >
                        <option value="">Reason:</option>
                        {reasonCodes.map(({ code, description }) => {
                            if (code === '06') {
                                return null;
                            }

                            return <option key={code}>{description}</option>;
                        })}
                    </select>
                </td>
                <td className="action-2">
                    <select
                        name={`quantity-${ID}`}
                        className="form-control action-select"
                        onChange={e => {
                            this.onActionCreateRowChange(e);
                            this.checkChangeMade();
                        }}
                        disabled="disabled"
                    >
                        <option value="">QTY:</option>
                        {quantityOptions.map((number, index) => (
                            <option key={index}>{number}</option>
                        ))}
                    </select>
                </td>
            </Fragment>
        );
    };

    getUser = () => {
        const { currentUser } = this.props;
        const { firstName, lastName } = currentUser;
        return { firstName, lastName };
    };

    getFullName = user => {
        if (!user) {
            return;
        }
        const { firstName, lastName } = user || {};
        return [firstName, lastName].filter(Boolean).join(' ');
    };

    getRefundShow = lines => {
        let { reasonCode, type: action, user, created, quantity } = lines[0];

        const descriptionReason = reasonCodeDescription(reasonCode);
        const processedBy = this.getFullName(user) || 'N/A';

        if (lines.length === 2) {
            action =
                (lines[0].type === 'return' || lines[0].type === 'refund') &&
                (lines[1].type === 'return' || lines[1].type === 'refund')
                    ? 'Refund & Return'
                    : action;
        }

        return (
            <Fragment>
                <td className="refund-exchange-actions">
                    <span>Action:</span> {action.charAt(0).toUpperCase() + action.slice(1)}
                    <br />
                    {descriptionReason && (
                        <Fragment>
                            <span>Reason:</span> {descriptionReason}
                            <br />
                        </Fragment>
                    )}
                    <span>Qty:</span> {quantity}
                </td>
                <td className="refund-exchange-actions">
                    <span>Processed by:</span> {processedBy}
                    <br />
                    <span>Date:</span> {formatDateDefault(created)}
                </td>
            </Fragment>
        );
    };

    productsHaveAction = (action, lines) => {
        let actionExists = false;

        Object.keys(lines).forEach(lineNumber => {
            const amendItems = lines[lineNumber].amends || [];

            amendItems.forEach(amendItem => {
                if (amendItem.type === action) {
                    actionExists = true;
                }
            });
        });

        return actionExists;
    };

    checkAllProductsHaveAction = (action, productItem) => {
        let result = false,
            productsHaveRefund = null,
            productsHaveReturn = null;
        const { lines } = productItem;

        switch (action) {
            case 'refund':
                productsHaveRefund = this.productsHaveAction(action, lines);
                result = productsHaveRefund;
                break;
            case 'refundreturn':
                productsHaveRefund = this.productsHaveAction('refund', lines);
                productsHaveReturn = this.productsHaveAction('return', lines);

                result = productsHaveRefund === productsHaveReturn;
                break;
            default:
                throw new Error('Invalid product action given');
        }

        return result;
    };

    checkFullActionPerformed = () => {
        const { fullActionPerformed: oldFullActionPerformed } = this.state;
        const { order } = this.props;

        let fullActionPerformed = true,
            breakOutLoop = false;

        for (let itemKey in order.items) {
            for (let itemBrand in order.items[itemKey].items) {
                const item = order.items[itemKey].items[itemBrand];

                if (typeof item.lines === 'undefined') {
                    fullActionPerformed = false;
                    breakOutLoop = true;
                    break;
                }

                // fast way to check if full item not performed for product:
                // if actions taken not equal to quantity bought
                const productActionsCount = this.getProductActionsCount(item);
                if (productActionsCount !== item.quantity) {
                    fullActionPerformed = false;
                    breakOutLoop = true;
                    break;
                }

                // check if all actions match refund or refund & return
                const checkProductsHaveRefund = this.checkAllProductsHaveAction('refund', item);
                const checkProductsHaveRefundReturn = this.checkAllProductsHaveAction(
                    'refundreturn',
                    item,
                );

                if (!checkProductsHaveRefund && !checkProductsHaveRefundReturn) {
                    fullActionPerformed = false;
                    breakOutLoop = true;
                    break;
                }
            }

            if (breakOutLoop) {
                break;
            }
        }

        if (oldFullActionPerformed === fullActionPerformed) {
            return;
        }

        this.setFullActionPerformedState(fullActionPerformed);
    };

    checkDeliveryRefundSet = () => {
        const { order } = this.props;

        return typeof order.delivery.refund === 'undefined' ? false : true;
    };

    getOrderItems = () => {
        const { order } = this.props;
        let orderIDs = [];

        for (let itemKey in order.items) {
            let item = order.items[itemKey];

            orderIDs.push(item);
        }

        return orderIDs;
    };

    removeIsInvalidSelector = element => {
        const isInvalid = new RegExp(/is-invalid/g);

        const classnames = element[0].className;
        if (!classnames.match(isInvalid)) {
            return;
        }
        element[0].className = classnames.replace(isInvalid, '');
    };

    checkChangeMade = () => {
        // reset change made status - making submit button inoperable
        const itemIDs = this.getItemIDs();
        let actionTDs = [];

        itemIDs.forEach(itemID => {
            let allActionTDs = document.querySelectorAll(`[data-faciaid='${itemID}']`)[0]
                .parentNode;

            actionTDs.push(allActionTDs);
        });

        const { deliveryRefundReason, newDeliveryRefund } = this.state;
        let orderHasChange = false;
        if (
            deliveryRefundReason !== defaults.deliveryRefundReason &&
            newDeliveryRefund !== '0.00'
        ) {
            orderHasChange = true;
        }

        actionTDs.forEach(actionTableData => {
            let actionOne = actionTableData.querySelector('.action-1');
            let actionTwo = actionTableData.querySelector('.action-2');

            for (let i = 0; i < actionOne.children.length; i++) {
                if (actionOne.children[i].value !== '') {
                    orderHasChange = true;
                    break;
                }
            }
            for (let i = 0; i < actionTwo.children.length; i++) {
                if (actionTwo.children[i].value !== '') {
                    orderHasChange = true;
                    break;
                }
            }
        });

        // find if any order facia line item refund & return checkboxes set to true
        const refundReturnActionsMade = document.querySelectorAll("input[data-checked='true']");
        if (refundReturnActionsMade.length) {
            orderHasChange = true;
        }

        const { changeMade } = this.state;

        if (orderHasChange === true && changeMade === false) {
            this.toggleChangeMadeState();
        } else if (orderHasChange === false && changeMade === true) {
            this.toggleChangeMadeState();
        }
    };

    getItemIDs = () => {
        const { order } = this.props;
        const orderFacias = this.getFaciaList(order.items);

        let itemIDs = [];

        orderFacias.forEach(facia => {
            order.items[facia].items.forEach(({ ID }) => {
                itemIDs.push(ID);
            });
        });

        return itemIDs;
    };

    getLatestAmendLine = (productID, vendorOrderID) => {
        const { order } = this.props;
        let amendLine = 0,
            breakOut = false;

        for (let itemBrand in order.items) {
            const { orderNumber } = order.items[itemBrand];

            if (orderNumber !== vendorOrderID) {
                continue;
            }

            for (let itemKey in order.items[itemBrand].items) {
                const { ID, lines } = order.items[itemBrand].items[itemKey];

                if (productID !== ID) {
                    continue;
                }

                if (typeof lines !== 'undefined') {
                    amendLine = parseInt(Object.keys(lines)[Object.keys(lines).length - 1]);
                }

                breakOut = true;
                break;
            }
            if (breakOut) {
                break;
            }
        }

        return amendLine;
    };

    refundModalCleanup = () => {
        this.setChosenRefundDeliveryIDState(false);
    };

    processSubmitting = submitting => {
        // There's scope to further simplify. I can't see right now how processSubmitting and state.submitting differ but I think we can get rid of one of these states
        this.setState({ processSubmitting: submitting });
        // if (!document.querySelector('.save-changes-btn')) return;
        // if (submitting) {
        //     document.querySelector('.save-changes-btn').textContent = 'Processing...';
        //     document.querySelector('.save-changes-btn').disabled = true;
        //     document.querySelector('.issue-refund-btn').disabled = true;
        //     document.querySelector('.issue-refund-return-btn').disabled = true;
        // } else {
        //     document.querySelector('.save-changes-btn').textContent = 'Process';
        //     document.querySelector('.save-changes-btn').disabled = false;
        //     document.querySelector('.issue-refund-btn').disabled = false;
        //     document.querySelector('.issue-refund-return-btn').disabled = false;
        // }
    };

    reloadOrderNotes = () => {
        const orderNotes = this.getNotes();

        this.setState({ orderNotes });
    };

    validateAndPrepareAmendments = async ({ displayErrorDialog, order, user }) => {
        this.processSubmitting(true);
        const itemIDs = this.getItemIDs();
        let dataToAmend = {},
            orderAmends = [],
            orderActionParams = null;

        const { deliveryOption, deliveryRefundReason, newDeliveryRefund } = this.state;
        const deliveryRefundReasonHasNewValue = this.deliveryRefundReasonHasNewValue();
        const newDeliveryRefundHasValue = this.newDeliveryRefundHasNewValue();

        const deliveryRefundReasonEl = this.getDeliveryRefundReasonEl();
        const newDeliveryRefundEl = this.getNewDeliveryRefundEl();
        let cancelSubmission = false;

        this.removeIsInvalidSelector(deliveryRefundReasonEl);
        this.removeIsInvalidSelector(newDeliveryRefundEl);

        if (deliveryRefundReasonHasNewValue && newDeliveryRefundHasValue) {
            const reason = reasonCodes.filter(
                reasonCode => reasonCode.description === deliveryRefundReason,
            );

            const refundAmount = Number(newDeliveryRefund) * 100;
            let errorMsg = 'Unknown error encountered';

            if (reason.length === 0) {
                cancelSubmission = true;
            } else if (!newDeliveryRefund.match(/^\d+(\.\d+)?$/)) {
                newDeliveryRefundEl[0].className += ' is-invalid';
                errorMsg =
                    'Delivery refund must be greater than zero and in the following format: £1 or £1.00';
                cancelSubmission = true;
            } else if (
                refundAmount > deliveryOption.price ||
                refundAmount > order.totals.delivery ||
                refundAmount <= 0
            ) {
                newDeliveryRefundEl[0].className += ' is-invalid';
                errorMsg = 'Delivery refund must be between zero and the remaining amount';
                cancelSubmission = true;
            }

            if (cancelSubmission) {
                displayErrorDialog('Issue Processing Changes', errorMsg);
                this.processSubmitting(false);
                return;
            }

            orderActionParams = {
                action: 'partialdeliveryrefund',
                reasonCode: reason[0].code,
                groupOrderID: order.ID,
                price: refundAmount,
                user,
            };
        } else if (deliveryRefundReasonHasNewValue && !newDeliveryRefundHasValue) {
            deliveryRefundReasonEl[0].className += ' is-invalid';
            newDeliveryRefundEl[0].className += ' is-invalid';
            displayErrorDialog('Process Changes', 'Missing fields for delivery refund');
            this.processSubmitting(false);
            return;
        }

        itemIDs.forEach(itemID => {
            const orderID = document.querySelectorAll(`[data-faciaid='${itemID}']`)[0].dataset
                .orderid;

            let allActionTDs = document.querySelectorAll(`[data-faciaid='${itemID}']`)[0]
                .parentNode;

            if (typeof dataToAmend[orderID] === 'undefined') {
                dataToAmend[orderID] = {
                    [itemID]: allActionTDs,
                };
            } else {
                dataToAmend[orderID][itemID] = allActionTDs;
            }
        });

        for (let vendorOrderID in dataToAmend) {
            for (let sku in dataToAmend[vendorOrderID]) {
                let product = dataToAmend[vendorOrderID][sku];
                let orderAmendment = {
                    groupOrderID: order.ID,
                    vendorOrderID,
                    items: [],
                    user,
                };

                let readyToSubmit = true;

                if (readyToSubmit) {
                    const actionValue = product.querySelector('.action-1').children[0].value;
                    const qtyValue = product.querySelector('.action-2').children[0].value;
                    const reasonValue = product.querySelector('.action-1').children[1].value;

                    const reasonCodeData = reasonCodeLookup(reasonValue);
                    const reasonCode = reasonCodeData ? reasonCodeData.code : null;
                    if (typeof reasonCode === 'undefined') {
                        break;
                    }

                    if (actionValue !== 'Return & Refund') {
                        orderAmendment.items.push({
                            type: actionValue.toLowerCase(),
                            sku,
                            quantity: parseInt(qtyValue),
                            reasonCode,
                        });
                    } else {
                        orderAmendment.items.push({
                            type: 'return',
                            sku,
                            quantity: parseInt(qtyValue),
                            reasonCode,
                        });

                        orderAmendment.items.push({
                            type: 'refund',
                            sku,
                            quantity: parseInt(qtyValue),
                            reasonCode,
                        });
                    }

                    orderAmends.push(orderAmendment);
                }
            }
        }
        return { cancelSubmission, orderAmends, orderActionParams };
    };

    validateAmendments = async () => {
        const { displayErrorDialog, order, orderAction } = this.props;
        const user = this.getUser();
        const { cancelSubmission } = await this.validateAndPrepareAmendments({
            displayErrorDialog,
            orderAction,
            order,
            user,
        });
        return !!cancelSubmission;
    };

    submitAmendments = async () => {
        const { processOrderAmendment, displayErrorDialog, order, orderAction } = this.props;
        const user = this.getUser();
        const { cancelSubmission, orderAmends, orderActionParams } =
            await this.validateAndPrepareAmendments({
                displayErrorDialog,
                orderAction,
                order,
                user,
            });

        if (cancelSubmission) {
            return;
        }
        // find out default value for order facia line item refund & return checkboxes
        const refundReturnActionsMade = document.querySelectorAll("input[data-checked='true']");

        refundReturnActionsMade.forEach(refundReturnAction => {
            const lineNumber = refundReturnAction.parentNode.querySelector(
                "input[name='line-number']",
            ).value;
            const firstTD =
                refundReturnAction.parentNode.parentNode.parentNode.parentNode.children[0];
            const orderID = firstTD.attributes['data-orderid'].value;
            const faciaID = firstTD.attributes['data-faciaid'].value;
            const action = refundReturnAction.parentNode.querySelector('label').textContent;

            let orderAmendment = {
                groupOrderID: order.ID,
                vendorOrderID: orderID,
                items: [],
                user,
            };

            // get reason code from amend already present
            let reasonCode = null,
                qtyValue = 1,
                breakOut = false;

            for (let itemBrand in order.items) {
                for (let itemKey in order.items[itemBrand].items) {
                    const { ID, lines } = order.items[itemBrand].items[itemKey];

                    if (ID !== faciaID) {
                        continue;
                    }

                    const amendItems = lines[lineNumber].amends || [];

                    if (amendItems && amendItems.length > 0) {
                        reasonCode = amendItems[0].reasonCode;
                        qtyValue = amendItems[0].quantity;
                    }

                    breakOut = true;
                    break;
                }
                if (breakOut) {
                    break;
                }
            }

            orderAmendment.items.push({
                quantity: parseInt(qtyValue),
                line: parseInt(lineNumber),
                type: action.toLowerCase(),
                sku: faciaID,
                reasonCode,
            });

            orderAmends.push(orderAmendment);
        });

        for (const amendment of orderAmends) {
            await processOrderAmendment(amendment);
        }

        if (orderActionParams) {
            await orderAction(orderActionParams);
        }

        this.processSubmitting(false);
    };

    isKioskOrder = channel => {
        return ['kiosk', 'webtill', 'ipad'].includes(channel);
    };

    // Button event methods
    issueFullRefund = () => {
        this.setIssueFullRefundSubmitted(true);

        const { orderAction, order, displayErrorDialog } = this.props;
        const { refundReason, refundModalType } = this.state;

        const user = this.getUser();

        if (0 === refundReason.length) {
            return;
        }

        this.setRefundModalState(false);

        const reason = reasonCodes.filter(reasonCode => reasonCode.description === refundReason);

        if (reason.length === 0) {
            return;
        }

        const { chosenRefundDeliveryID } = this.state;
        let orderAmendment = {
            reasonCode: reason[0].code,
            action: refundModalType,
            groupOrderID: order.ID,
            user,
        };

        try {
            if (this.isKioskOrder(order.channel)) {
                orderAmendment.deliveryOptionID = order.delivery.deliveryOptionID;
                this.processSubmitting(true);
            } else {
                if (chosenRefundDeliveryID) {
                    if (order.delivery.ID !== chosenRefundDeliveryID) {
                        throw new Error('Refund delivery does not match order delivery');
                    }
                    orderAmendment.deliveryOptionID = order.delivery.deliveryOptionID;
                    this.processSubmitting(true);
                } else {
                    throw new Error('Full order delivery option not found');
                }
            }

            const deliveryRefundAlreadySet = this.checkDeliveryRefundSet();

            if (deliveryRefundAlreadySet) {
                throw new Error('Full order delivery refund already set');
            }

            orderAction(orderAmendment);
        } catch (err) {
            displayErrorDialog(
                'Oops, something went wrong',
                'An unexpected error occurred. We are sorry about this and working to get this fixed. Error code 52',
            );
            console.log(err);
        }

        this.setChosenRefundDeliveryIDState(false);

        this.setIssueFullRefundSubmitted(false);
    };

    onCollapseClick = () => this.toggleNotesCollapseOpen();

    onPaginationBtnClick = async e => {
        const { currentNotesPage } = this.state;
        const orderNotes = this.getNotes();

        let newCurrentNotesPage = currentNotesPage;

        switch (e.target.id) {
            case 'firstPage':
                if (newCurrentNotesPage > 1) {
                    newCurrentNotesPage = 1;
                }
                break;
            case 'prevPage':
                if (newCurrentNotesPage > 1) {
                    newCurrentNotesPage -= 1;
                }
                break;
            case 'nextPage':
                if (newCurrentNotesPage + 1 <= orderNotes.pageCount) {
                    newCurrentNotesPage += 1;
                }
                break;
            case 'lastPage':
                if (newCurrentNotesPage + 1 <= orderNotes.pageCount) {
                    newCurrentNotesPage = orderNotes.pageCount;
                }
                break;
            default:
                break;
        }

        if (newCurrentNotesPage !== currentNotesPage) {
            this.setCurrentNotesPageState(newCurrentNotesPage, this.reloadOrderNotes);
        }
    };

    onCancelOrderClick = () => {
        const { cancelOrder, order } = this.props;
        confirmAlert({
            title: 'Request Cancellation',
            message: `Are you sure you want to cancel order ${order.ID}`,
            buttons: [
                {
                    label: 'Request Cancellation',
                    onClick: () => cancelOrder(order.ID),
                },
                {
                    label: 'Cancel',
                },
            ],
        });
    };

    openFullRefundModal = () => {
        this.setRefundModalTypeState(this.refundModalTypes.REFUND);
        this.setRefundModalState(true);
    };

    openFullRefundReturnModal = () => {
        this.setRefundModalTypeState(this.refundModalTypes.REFUNDRETURN);
        this.setRefundModalState(true);
    };

    onSaveChangeClick = async () => {
        const { displayErrorDialog } = this.props;
        const { changeMade } = this.state;

        if (changeMade) {
            await this.submitAmendments();
        } else {
            displayErrorDialog('Process order', 'No change has been made');
        }
    };

    onActionCreateRowChange = e => {
        let isAction = false,
            isQTY = false,
            isReason = false;

        const { changeMade } = this.state;
        if (changeMade !== true) {
            this.toggleChangeMadeState();
        }

        const { name, value } = e.target;

        const elName = name.substring(0, name.indexOf('-'));

        if (elName === 'action') {
            isAction = true;
        } else if (elName === 'quantity') {
            isQTY = true;
        } else if (elName === 'reason') {
            isReason = true;
        } else if (elName === 'size') {
            return;
        }

        if (isReason === false) {
            let actionAndQTYSet = false;
            if (isAction && value.length > 0) {
                const { value: quantityValue } =
                    e.target.parentNode.parentNode.querySelector('.action-2').children[0];

                e.target.parentNode.parentNode.querySelector(
                    '.action-2',
                ).children[0].disabled = false; // enable quantity el if action set

                if (quantityValue.length > 0) {
                    actionAndQTYSet = true;
                }

                // get remaining quantity for item
                let quantityRemaining = parseInt(
                    e.target.parentNode.parentNode.children[2].textContent,
                );
                if (1 === quantityRemaining) {
                    e.target.parentNode.parentNode.querySelector('.action-2').children[0].value = 1;

                    actionAndQTYSet = true;
                }
            } else {
                if (isAction && value.length < 1) {
                    e.target.parentNode.parentNode.querySelector(
                        '.action-2',
                    ).children[0].disabled = true; // disable quantity el if action not set
                    e.target.parentNode.parentNode.querySelector('.action-2').children[0].value =
                        '';
                }

                if (isQTY && value.length > 0) {
                    const { value: actionValue } =
                        e.target.parentNode.parentNode.querySelector('.action-1').children[0];

                    if (actionValue.length > 0) {
                        actionAndQTYSet = true;
                    }
                }
            }

            // check if action & quantity have value - if so enable reason element
            // else disabled reason element
            // above also applies for size element
            e.target.parentNode.parentNode.querySelector('.action-1').children[1].disabled =
                actionAndQTYSet ? false : true;

            if (isReason === false) {
                e.target.parentNode.parentNode.querySelector('.action-1').children[1].value = '';
            }
        }
    };

    onNoteTextChange = e => {
        const { value } = e.target;

        this.setNoteTextState(value);
    };

    onAddNoteClick = async () => {
        try {
            const { noteText, noteData } = this.state;
            const { addOrderNote, order } = this.props;

            const addNoteOrder = {
                groupOrderID: order.ID,
                note: {
                    text: noteText,
                    user: this.getUser(),
                },
            };

            await addOrderNote(addNoteOrder);
            noteData.unshift({
                text: addNoteOrder.note.text,
                user: addNoteOrder.note.user,
                createdAt: Date.now(),
            });

            this.setNoteDataState(noteData);
            this.setNoteTextState('', this.reloadOrderNotes);
        } catch (e) {
            console.log('Error fetching user credentials', e);
        }
    };

    onNotesRowChange = e => {
        if (null !== e.target.value.match(/\d/)) {
            let notesRowsPerPage = parseInt(e.target.value);
            this.setNotesRowsPerPageState(notesRowsPerPage, this.reloadOrderNotes);
        }
    };

    onRefundReturnActionChange = e => {
        const { displayErrorDialog } = this.props;

        try {
            if (e.target.attributes['data-checked'].value === 'false') {
                e.target.setAttribute('data-checked', true);
            } else {
                e.target.setAttribute('data-checked', false);
            }

            this.checkChangeMade();
        } catch (err) {
            displayErrorDialog(
                'Oops, something went wrong',
                'An unexpected error occurred. We are sorry about this and working to get this fixed. Error code 23',
            );
        }
    };

    onDeliveryRefundRefreshClick() {
        const deliveryRefundReasonEl = this.getDeliveryRefundReasonEl();
        const newDeliveryRefundEl = this.getNewDeliveryRefundEl();

        this.removeIsInvalidSelector(deliveryRefundReasonEl);
        this.removeIsInvalidSelector(newDeliveryRefundEl);

        this.setNewDeliveryRefundState();
        this.setDeliveryRefundReasonState();
    }

    render() {
        const {
            issueFullRefundSubmitted,
            deliveryRefundReason,
            fullActionPerformed,
            notesCollapseOpen,
            newDeliveryRefund,
            currentNotesPage,
            disableAllAction,
            refundModalOpen,
            refundModalType,
            availableFacia,
            selectedFacia,
            refundReason,
            orderStatus,
            changeMade,
            submitting,
            orderNotes,
            noteText,
            canRefundOrders,
        } = this.state;
        const { currentUser, order } = this.props;

        const faciaDisplayText = this.faciaDisplayText;

        const processBtnClass = changeMade
            ? 'btn save-changes-btn save-changes-success'
            : 'btn save-changes-btn';

        // const cancelBtnClass =
        //     typeof orderStatus === 'object' && orderStatus.text === 'pending'
        //         ? 'btn cancel-order-btn'
        //         : 'btn cancel-order-btn d-none';

        const issueRefundBtnClass =
            typeof orderStatus === 'object' && orderStatus.text === 'paid' && !fullActionPerformed
                ? 'btn issue-refund-btn'
                : 'btn issue-refund-btn d-none';
        const issueRefundReturnBtnClass =
            typeof orderStatus === 'object' && orderStatus.text === 'paid' && !fullActionPerformed
                ? 'btn issue-refund-return-btn'
                : 'btn issue-refund-return-btn d-none';

        return (
            <div className="container-flex order-page">
                {Object.keys(orderNotes).length ? (
                    <div className="row">
                        <div className="col-md-4 col-sm-4">
                            <div className="form-group">
                                <select
                                    className="form-control"
                                    name="selected_facia"
                                    id="selected_facia"
                                    value={selectedFacia}
                                    onChange={e => this.onSelectedFaciaChange(e)}
                                >
                                    <option value="">VIEW: ALL FASCIAS</option>
                                    {availableFacia.map((facia, index) => (
                                        <option key={index} value={facia}>
                                            {facia.toUpperCase()}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-8 col-sm-8">
                            <div className="float-right">
                                {/* <button
                                    className={cancelBtnClass}
                                    onClick={e => this.onCancelOrderClick(e)}
                                    disabled={!disableAllAction}
                                >
                                    Cancel Order
                                </button> */}
                                {canRefundOrders && (
                                    <button
                                        className={issueRefundBtnClass}
                                        onClick={() => this.openFullRefundModal()}
                                        disabled={disableAllAction || this.state.processSubmitting}
                                    >
                                        Issue Full Refund
                                    </button>
                                )}
                                {canRefundOrders && (
                                    <button
                                        className={issueRefundReturnBtnClass}
                                        onClick={() => this.openFullRefundReturnModal()}
                                        disabled={disableAllAction || this.state.processSubmitting}
                                    >
                                        Issue Full Refund &amp; Return
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                ) : null}
                {Object.keys(orderNotes).length ? (
                    <div className="row">
                        <div className="col-md-12">
                            <OrderPageCollapse
                                onNotesRowChange={this.onNotesRowChange.bind(this)}
                                onPaginationBtnClick={this.onPaginationBtnClick.bind(this)}
                                onCollapseClick={this.onCollapseClick.bind(this)}
                                onTextChange={this.onNoteTextChange.bind(this)}
                                onAddNoteClick={this.onAddNoteClick.bind(this)}
                                notesCollapseOpen={notesCollapseOpen}
                                currentNotesPage={currentNotesPage}
                                orderNotes={orderNotes}
                                noteText={noteText}
                            />
                        </div>
                    </div>
                ) : (
                    <StandardLoader />
                )}
                <OrderPageItemTable
                    setDeliveryRefundReason={this.setDeliveryRefundReasonState.bind(this)}
                    setNewDeliveryRefund={this.setNewDeliveryRefundState.bind(this)}
                    onRefundReturnActionChange={this.onRefundReturnActionChange.bind(this)}
                    onDeliveryRefundRefresh={this.onDeliveryRefundRefreshClick.bind(this)}
                    getRefundEdit={this.getRefundEdit}
                    getRefundShow={this.getRefundShow}
                    deliveryRefundReason={deliveryRefundReason}
                    newDeliveryRefund={newDeliveryRefund}
                    faciaDisplayText={faciaDisplayText}
                    selectedFacia={selectedFacia}
                    order={order}
                    user={currentUser}
                    validateAmendments={this.validateAmendments.bind(this)}
                />
                <div className="row">
                    <div className="col-md-12">
                        <div className="float-right">
                            <button
                                className={processBtnClass}
                                disabled={!changeMade || this.state.processSubmitting}
                                onClick={async e => await this.onSaveChangeClick(e)}
                            >
                                {submitting ? 'Processing' : 'Process'}
                            </button>
                        </div>
                    </div>
                </div>
                <IssueFullRefund
                    refundModalCleanup={this.refundModalCleanup.bind(this)}
                    setRefundReason={this.setRefundReasonState}
                    refundModalTypes={this.refundModalTypes}
                    setModalOpen={this.setRefundModalState}
                    issueFullRefund={this.issueFullRefund}
                    submitted={issueFullRefundSubmitted}
                    refundModalType={refundModalType}
                    modalOpen={refundModalOpen}
                    refundReason={refundReason}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        order: state.order.order,
        vendorOrder: state.order.vendorOrder,
        amendingOrder: state.order.amendingOrder,
        orderAmendment: state.order.orderAmendment,
        currentUser: state.auth.currentUser,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setAmendingOrder: amending => dispatch(orderActions.setAmendingOrder(amending)),
        processOrderAmendment: async orderAmendment =>
            await dispatch(orderActions.processOrderAmendment(orderAmendment)),
        addOrderNote: orderAmendment => dispatch(orderActions.addOrderNote(orderAmendment)),
        refundOrderDeliveryMethod: orderAmendment =>
            dispatch(orderActions.refundOrderDeliveryMethod(orderAmendment)),
        displayErrorDialog: (title, message) =>
            dispatch(layoutActions.displayErrorDialog(title, message)),
        getOrder: ID => dispatch(orderActions.getOrder(ID)),
        orderAction: async orderAmendment =>
            await dispatch(orderActions.orderAction(orderAmendment)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderPage);
