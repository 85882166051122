import CheckIcon from '@material-ui/icons/Check';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { Checkbox } from '@rmwc/checkbox';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import networkGroups from '../../config/networkGroups';
import { capitaliseStr } from '../../constants';
import { convertTimeFromSeconds, convertTimeToSeconds } from '../../utils/time';

const formatPrice = price => {
    const currency = '£';
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (!regex.test(`${price}`)) {
        return `${currency}${price.replace(/[^0-9.]/g, '')}`;
    }
    return `${currency}${price}`;
};

const DeliveryOptionPage = ({ editDelivery, onFieldChange, fascias }) => {
    const [checkoutDeliveryPromise, setCheckoutDeliveryPromise] = useState(
        editDelivery.description,
    );
    const checkoutDeliveryPromiseMaxLength = 100;

    useEffect(() => {
        setCheckoutDeliveryPromise(editDelivery.description || '');
    }, [editDelivery.description]);

    if (!editDelivery) {
        return '';
    }

    return (
        <>
            <div className="row mt-3">
                <div className="col-md-12">
                    <div className="float-left">
                        <h4 className="deliveryoption-name-header">{editDelivery.name}</h4>
                    </div>
                    <div className="float-right">
                        <p className="active-text">
                            STATUS:
                            <span>{editDelivery ? ' ACTIVE' : ' INACTIVE'}</span>
                        </p>
                        <ToggleButton
                            className="active-toggle"
                            value="check"
                            name="active"
                            selected={editDelivery.active === true}
                            onChange={() => onFieldChange('active', !editDelivery.active)}
                        >
                            <CheckIcon />
                        </ToggleButton>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="form-row">
                        <div className="col-md-2">
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                onChange={e => onFieldChange(e.target.name, e.target.value)}
                                value={editDelivery.name}
                            />
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="code">Code</label>
                            <input
                                type="text"
                                name="code"
                                className="form-control"
                                onChange={e => onFieldChange(e.target.name, e.target.value)}
                                value={editDelivery.code}
                            />
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="carriercode">Carrier Code</label>
                            <input
                                type="text"
                                name="carriercode"
                                className="form-control"
                                onChange={e => onFieldChange('carrierCode', e.target.value)}
                                value={editDelivery.carrierCode}
                            />
                        </div>
                        <div className="col-md-1">
                            <label htmlFor="price">Price</label>

                            <div className="input-group mb-3 price-container">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="price"
                                    onChange={e => onFieldChange(e.target.name, e.target.value)}
                                    value={formatPrice(editDelivery.price)}
                                />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="fascia">Fascia</label>

                            <div className="input-group mb-3">
                                <select
                                    name="fascia"
                                    className="form-control"
                                    value={editDelivery.fascia || ''}
                                    onChange={e => onFieldChange(e.target.name, e.target.value)}
                                >
                                    {fascias &&
                                        fascias.map(fascia => {
                                            const formattedFasciaName = capitaliseStr(fascia);
                                            return (
                                                <option key={fascia} value={fascia}>
                                                    {formattedFasciaName}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <label htmlFor="deliverytype">Delivery Type</label>
                            <div className="input-group mb-3">
                                <select
                                    name="deliverytype"
                                    className="form-control"
                                    value={editDelivery.deliveryType}
                                    onChange={e => onFieldChange('deliveryType', e.target.value)}
                                    required
                                >
                                    <option key="Please choose a delivery type" value="">
                                        Please choose a delivery type
                                    </option>

                                    <option key="Home" value="home">
                                        Home
                                    </option>

                                    <option key="Store" value="store">
                                        Store
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="form-row">
                        <div className="col-md-10">
                            <label htmlFor="description">Checkout Delivery Promise</label>
                            <textarea
                                type="text"
                                name="description"
                                className="form-control"
                                value={editDelivery.description}
                                maxLength={checkoutDeliveryPromiseMaxLength}
                                onChange={e => {
                                    onFieldChange(e.target.name, e.target.value);
                                    setCheckoutDeliveryPromise(e.target.value);
                                }}
                            ></textarea>
                            <div className="col-md-10">
                                Character Count: {checkoutDeliveryPromise.length}/
                                {checkoutDeliveryPromiseMaxLength}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="form-row">
                        <div className="col-md-10">
                            <label htmlFor="pdpPromise">PDP Delivery Promise</label>
                            <textarea
                                type="text"
                                name="pdpPromise"
                                className="form-control"
                                value={editDelivery.pdpPromise}
                                onChange={e => onFieldChange(e.target.name, e.target.value)}
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="form-row">
                        <div className="col-md-2">
                            <label htmlFor="cutOffTime">Cut Off Time</label>
                            <input
                                type="time"
                                name="cutOffTime"
                                className="form-control"
                                onChange={e =>
                                    onFieldChange(
                                        e.target.name,
                                        convertTimeToSeconds(e.target.value),
                                    )
                                }
                                value={convertTimeFromSeconds(editDelivery.cutOffTime)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <div className="row top-margin">
                        <div className="col-md-12">
                            <label>Anatwine Status</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {networkGroups.map(networkGroup => (
                                <div key={networkGroup.id}>
                                    <Checkbox
                                        name={'anatwine-' + networkGroup.id}
                                        checked={editDelivery['anatwine-' + networkGroup.id]}
                                        onChange={() =>
                                            onFieldChange(
                                                'anatwine-' + networkGroup.id,
                                                !editDelivery['anatwine-' + networkGroup.id],
                                            )
                                        }
                                        value={editDelivery['anatwine-' + networkGroup.id] || false}
                                    />
                                    <label htmlFor={'anatwine-' + networkGroup.id}>
                                        {networkGroup.label}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row">
                        <div
                            className="col-md-12"
                            title="This setting controls if the delivery method will deliver to the restricted postcodes. Setting this value will prevent delivery to the restricted postcodes"
                        >
                            <div className="form-row">
                                <div className="col-md-10">
                                    <p className="active-text">
                                        POSTCODE RESTRICTED:
                                        <span>
                                            {editDelivery && editDelivery.postcodeRestricted
                                                ? ' YES'
                                                : ' NO'}
                                        </span>
                                    </p>
                                    <ToggleButton
                                        className="active-toggle"
                                        value="check"
                                        name="postcodeRestricted"
                                        selected={editDelivery.postcodeRestricted === true}
                                        onChange={() =>
                                            onFieldChange(
                                                'postcodeRestricted',
                                                !editDelivery.postcodeRestricted,
                                            )
                                        }
                                    >
                                        <CheckIcon />
                                    </ToggleButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

function mapStateToProps(state) {
    return {
        channels: state.utils.channels,
        fascias: state.utils.fascias,
    };
}

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryOptionPage);
